import { useState, useEffect } from 'react';
import FullScreen from "./FullScreen"

const Ad = ({data, close}) => {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        if (data) {
            setOpen(true)
        }
    },[data])
    
    if (data?.adType === 'fullScreen') {
        return <FullScreen open={open} data={data} close={() => close()}/>
    }

    return <></>

   
}

export default Ad