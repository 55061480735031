import React from "react";
import {
  Grid,
  Paper,
  Avatar,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FavoriteIcon from "@mui/icons-material/Favorite";

const AttendeeCard = ({ attendee, handleSelect }) => {

  if (attendee?.matchScore === undefined) return null

  return (
    <Grid item xs={12}>
      <Paper
        elevation={3}
        style={{ padding: 10, cursor: "pointer" }}
        onClick={() => handleSelect(attendee)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 20,
            }}
          >
            {attendee?.profileImage ? (
              <Avatar src={attendee.profileImage} />
            ) : (
              <AccountCircleIcon
                style={{ width: 45, height: 45, color: "grey" }}
              />
            )}
            <Typography sx={{ width: 120 }}>
              {attendee?.firstName} {attendee?.lastName}
            </Typography>
            <Box
              sx={{
                position: "relative",
                display: "inline-flex",
                transform: "rotate(180deg)",
              }}
            >
              <CircularProgress
                variant="determinate"
                value={attendee?.matchScore}
                size={50}
                thickness={4}
                color="primary"
                aria-label={`Match score is ${attendee?.matchScore}%`}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{ fontWeight: "bold", transform: "rotate(180deg)" }}
                >
                  {`${attendee?.matchScore}%`}
                </Typography>
              </Box>
            </Box>
          </div>
          {attendee?.like ? <FavoriteIcon sx={{ color: "#68C371", width: 30, height: 30 }} /> : null}
          
          <KeyboardArrowRightIcon />
        </div>
      </Paper>
    </Grid>
  );
};

export default AttendeeCard;
