import { useState, useEffect, useContext } from "react";
import { Grid, Typography, CircularProgress } from "@mui/material";
import AttendeeCard from "../RoundComponents/AttendeeCard"
import AttendeePage from "../RoundComponents/AttendeePage";
import { AppContext } from "../../contexts/AppContext";
import request from "../../utilities/requests";

const MixerEvent = () => {
  const { event, mixerAttendees, setMixerAttendees } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [selectedAttendee, setSelectedAttendee] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
   
      getAttendees();
    
  }, []);

  const getAttendees = async () => {
    if (mixerAttendees.length === 0) {
      setLoading(true);
    }
    const result = await request(
      `pairingevent/mixerattendees?eventId=${event._id}`
    );
    if (result.success) {
      setMixerAttendees(result.attendees);
      console.log('mixer attendees refreshed');
    }
    setLoading(false);
  };

  const handleSelectAttendee = (attendee) => {
    setSelectedAttendee(attendee);
    setDialogOpen(true);
  };

  const handleClose = () => {
    getAttendees();
    setDialogOpen(false);
    setSelectedAttendee(null);
  };

  if (selectedAttendee) {
    return (
      <AttendeePage
        attendee={selectedAttendee}
        open={dialogOpen}
        close={handleClose}
      />
    );
  }

  const renderAttendees = () => {
    if (loading) {
      return (
        <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 100}}>
          <CircularProgress size={80}/>
        </Grid>
      );
    }

    return mixerAttendees.map((attendee) => {
      return (
        <AttendeeCard
          key={attendee?._id}
          attendee={attendee}
          handleSelect={handleSelectAttendee}
        />
      );
    });
  };

  return (
    <>
      <Grid container spacing={3} style={{ padding: 10 }}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            GUEST LIST
          </Typography>
        </Grid>
        {renderAttendees()}
      </Grid>
      <div style={{ height: 100 }} />
    </>
  );
};

export default MixerEvent;
