import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  FormHelperText,
} from "@mui/material";

const Choice = ({ question, onChange }) => {
  // Initialize the value with the existing answer
  const initialValue =
    question.questionType === "multipleChoice" ||
    (question.questionType === "related" && question.matchingCriteria === "multiple")
      ? question.answer
        ? question.answer.split(",")
        : []
      : question.answer || "";

  const [value, setValue] = useState(initialValue);
  
  // State to control the open state of the Select dropdown
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    const newValue = event.target.value;

    setValue(newValue); // Update local state

    // Format the value for multiple or single selection
    const formattedValue =
      question.questionType === "multipleChoice" ||
      (question.questionType === "related" && question.matchingCriteria === "multiple")
        ? Array.isArray(newValue)
          ? newValue.join(",")
          : newValue
        : newValue;
    onChange(question._id, formattedValue); // Notify parent

    // If multiple select, close the dropdown after selection
    if (isMultiple) {
      setOpen(false);
    }
  };

  // Handlers to open and close the dropdown
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Determine if the select should allow multiple selections
  const isMultiple =
    question.questionType === "multipleChoice" ||
    (question.questionType === "related" && question.matchingCriteria === "multiple");

  // Determine if there's an error: required and no answer
  const isError =
    question.required &&
    (value === "" ||
      value === null ||
      (Array.isArray(value) && value.length === 0));

  return (
    <Grid item xs={12}>
      <FormControl fullWidth error={isError}>
        <InputLabel>
          {question.question}
          {question.required ? " *" : ""}
        </InputLabel>
        <Select
          multiple={isMultiple} // Correctly passing a boolean
          label={question.question}
          value={value}
          onChange={handleChange}
          open={open} // Controlled open state
          onClose={handleClose} // Handle close event
          onOpen={handleOpen} // Handle open event
          renderValue={(selected) =>
            Array.isArray(selected)
              ? selected
                  .map(
                    (val) =>
                      question.choices.find((c) => c.value === val)?.label || ""
                  )
                  .join(", ")
              : question.choices.find((c) => c.value === selected)?.label || ""
          }
        >
          {question.choices.map((choice) => (
            <MenuItem key={choice.value} value={choice.value}>
              {choice.label}
            </MenuItem>
          ))}
        </Select>
        {/* Conditionally render FormHelperText for errors */}
        {isError && (
          <FormHelperText>This field is required.</FormHelperText>
        )}
        {question.description && (
          <Typography variant="caption" color="textSecondary" mt={1}>
            {question.description}
          </Typography>
        )}
      </FormControl>
    </Grid>
  );
};

export default Choice;