import * as React from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";

const HelpDialog = ({open, close}) => {



    return (
        <Dialog
        open={open}
        onClose={() => close()}
      >
        <DialogTitle id="alert-dialog-title">
          {"Here's how it works"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText >
            If you like someone, simply tap on the heart icon. Once you like them, the heart will turn green.
          </DialogContentText>
          <br />
          <DialogContentText >
           You can always come back and change your selection. Once the event is complete, if the person you liked likes you, you will both be connected via email. 
          </DialogContentText>
          <br />
          <DialogContentText >
            You can also takes notes by clicking on the notes icon. Once the event is complete, you will only be able to see the notes of the people you matched with. 
          </DialogContentText>
          <br />
          <DialogContentText >
            If you still have questions, please do not hestiate to ask an event host.
          </DialogContentText>
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={() =>  close()} autoFocus>
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    )

}

export default HelpDialog;