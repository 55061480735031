import * as React from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import request from "../../utilities/requests";
import { AppContext } from "../../contexts/AppContext";

const NotesDialog = ({ open, close, otherUser }) => {
  const {event} = React.useContext(AppContext);
  const [notes, setNotes] = React.useState("");


  React.useEffect(() => {
    getNotes();
  },[])

  const getNotes = async () => {
    const result = await request(`pairing/mixernotes?eventId=${event._id}&otherUser=${otherUser}`);
    if (result.success) {
      if (result?.notes?.text){
        setNotes(result?.notes?.text);
      } else {
        setNotes("");
      }
    }
  };

  const saveNotes = async () => {
    if (notes === "") {
      close();
      return
    }
    const body = {
      eventId: event._id,
      otherUser,
      notes
    }
    const result = await request('pairing/mixernotes', "post", body);
    if (!result?.success) {
      alert(result.error);
    }
    close()
  }

  return (
    <Dialog open={open} onClose={() => close()} fullWidth>
      <DialogTitle id="alert-dialog-title">{"Date notes"}</DialogTitle>
      <DialogContent>
        <TextField
          label="notes"
          multiline
          rows={10}
          fullWidth
          style={{ marginTop: 10 }}
          value={notes}
          onChange={(e) =>  setNotes(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()} autoFocus>
          Cancel
        </Button>
        <Button onClick={() => saveNotes()} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotesDialog;
