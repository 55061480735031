import React from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import request from "../../../utilities/requests";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  Typography,
  Chip,
  CircularProgress,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoDialog from "./InfoDialog";
import ConfirmRedirectDialog from "./ConfirmRedirectDialog";
import MessageDialog from "../../../components/MessageDialog";

import { AppContext } from "../../../contexts/AppContext";
import AuthContext from "../../../contexts/AuthContext";

import { toast } from "../../../utilities/SnackBarUtils";

import "../events.css";

const MyEvents = () => {
  const navigate = useNavigate();
  const { setActiveEvent, myEvents, setMyEvents, event } =
    React.useContext(AppContext);
  const { socket, setPromptData, listeningForPrompt, setListeningForPrompt } =
    React.useContext(AuthContext);
  const [loaded, setLoaded] = React.useState(false);
  const [showAll, setShowAll] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [eventUrl, setEventUrl] = React.useState(null);
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [checkInOpen, setCheckInOpen] = React.useState(false);
  const [dialogInfo, setDialogInfo] = React.useState({
    open: false,
    title: "",
    body: "",
    checkInMsg: false,
  });
  const [checkingIn, setCheckingIn] = React.useState(false);

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/myevents", title: "MyEvents" });
  }, []);

  React.useEffect(() => {
    if (!loaded) {
      getMyEvents();
      setLoaded(true);
    }
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getMyEvents = async () => {
    console.log("retrieving my events");
    const result = await request("events?registered=true");

    if (result.success) {
      setMyEvents([...result.events]);
    }
  };

  const getActiveEvent = async () => {
    const result = await request("events/activeevent");
    if (!result.success) {
      toast.error(result.error);
      navigate("/events");
    }
    setActiveEvent(result.event);
  };

  const handleOpen = (url) => {
    setOpen(true);
    setEventUrl(url);
  };
  const handleClose = () => {
    setOpen(false);
    setEventUrl(null);
  };

  const handleCloseInfo = () => {
    setInfoOpen(false);
  };

  const handleOpenLink = () => {
    window.open(eventUrl, "_blank", "noreferrer");
    setOpen(false);
    setEventUrl(null);
  };

  const handleCheckIn = async (event) => {
    setCheckingIn(true);
    const result = await request("events/checkin", "post", {
      eventId: event._id,
    });
    setCheckingIn(false);
    if (!result?.success && result?.incompleteProfile) {
      setDialogInfo({
        ...dialogInfo,
        open: true,
        title: "Please Complete Your Profile",
        body: result.message,
      });
      return;
    } else if (!result.success) {
      setDialogInfo({
        ...dialogInfo,
        open: true,
        title: "Error checking in",
        body: result.error,
      });
      return;
    }
    setDialogInfo({
      ...dialogInfo,
      open: true,
      title: "You are checked In!",
      body: "When the event starts, come back here and click on the 'Go to Event' button",
      checkInMsg: true,
    });
    await getMyEvents();
    setCheckInOpen(true);
    getActiveEvent();
  };

  const handleCheckInClose = async () => {
    if (dialogInfo.checkInMsg) {
      try {
        const body = {
          eventId: event._id,
          trigger: "afterCheckin",
        };
        await request("ads/ad/event", "post", body);
      } catch (e) {
        var error = `Error triggering ad check. Error: ${e.message}`;
        console.error(error);
      }
    }
    setDialogInfo({
      ...dialogInfo,
      open: false,
      title: "",
      body: "",
      checkInMsg: false,
    });
  };

  const formatDate = (date) => {
    let d = new Date(date);
    let dateString = d.toDateString();
    let hours = d.getHours();
    let minutes = d.getMinutes();

    let timeString;
    if (hours > 12 && minutes === 0) {
      timeString = `${hours - 12} PM`;
    } else if (hours > 12 && minutes > 0) {
      timeString = `${hours - 12}:${minutes} PM`;
    } else if (hours <= 12 && minutes === 0) {
      timeString = `${hours} AM`;
    } else if (hours <= 12 && minutes > 0) {
      timeString = `${hours}:${minutes} AM`;
    }

    return `${dateString} @ ${timeString}`;
  };

  const renderCardContent = (eventData) => {
    if (eventData.status === "completed") {
      return (
        <>
          <Typography variant="subtitle2" style={{ color: "#6e0101" }}>
            {formatDate(eventData.date)}
          </Typography>
          <Typography align="center" style={{ marginTop: 10 }}>
            Event Completed
          </Typography>
        </>
      );
    }
    if (!eventData.checkedIn) {
      return (
        <>
          <Typography variant="subtitle2" style={{ color: "#6e0101" }}>
            {formatDate(eventData.date)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {eventData.description}
          </Typography>
        </>
      );
    } else if (eventData.checkedIn && eventData.status !== "active") {
      return (
        <>
          <Typography variant="subtitle2" style={{ color: "#6e0101" }}>
            {formatDate(eventData.date)}
          </Typography>
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div> */}
        </>
      );
    } else if (eventData.checkedIn && eventData.status === "active") {
      if (eventData?._id !== event?._id) {
        console.log("refresh active event");
        getActiveEvent();
      }
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CheckCircleOutlineIcon className="event-ready-chk" />
        </div>
      );
    }
  };

  const renderGuestListBtn = () => {
    if (event?.showGuestList && event?.pairingEventType !== "mixer") {
      return (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            size="small"
            onClick={() =>
              navigate("/activeevent", { state: { page: "guestList" } })
            }
            fullWidth
          >
            View Guestlist
          </Button>
        </Grid>
      );
    }

    return null;
  };

  const renderButtons = (event) => {
    if (event.status === "scheduled") {
      return (
        <Grid
          item
          xs={6}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button onClick={() => setInfoOpen(true)}>Info</Button>
        </Grid>
      );
    } else if (
      ["pending", "active"].indexOf(event.status) > -1 &&
      !event.checkedIn
    ) {
      return (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleCheckIn(event)}
            disabled={event.checkedIn ? true : false}
            fullWidth
          >
            {checkingIn ? <CircularProgress size={20} /> : "Check In"}
          </Button>
        </Grid>
      );
    } else if (event.status === "pending" && event.checkedIn) {
      return (
        <Grid item xs={12}>
          <Button
            // variant="outlined"
            size="small"
            fullWidth
          >
            Event Starting Soon!
          </Button>
        </Grid>
      );
    } else if (event.status === "active" && event.checkedIn) {
      // setActiveEvent(event);
      let page;
      if (event?.pairingEventType === "mixer") {
        page = "mixer";
      } else {
        page = "speedDating";
      }

      return (
        <>
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="small"
              onClick={() => navigate("/activeevent", { state: { page } })}
              fullWidth
            >
              Go To Event
            </Button>
          </Grid>
          {renderGuestListBtn()}
        </>
      );
    } else if (event.status === "completed") {
      return (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => navigate("/eventresults", { state: { event } })}
            fullWidth
          >
            View Results
          </Button>
        </Grid>
      );
    }
    //TODO: work on this. Will need to update the view as there is no active event page to load
    // else if (event.status === 'completed') {
    //   return (
    //     <Button
    //       variant="outlined"
    //       size="small"
    //       onClick={() => navigate("/activeevent")}
    //     >
    //       Review Results
    //     </Button>
    //   )
    // }
  };

  const renderEvents = () => {
    if (myEvents.length === 0) {
      return (
        <Grid item>
          <Typography align="center">
            You have not signed up for any events yet
          </Typography>
        </Grid>
      );
    }

    return myEvents.map((event) => {
      // console.log(event.date)

      if (
        !showAll &&
        (event.status === "completed" || event.status === "canceled")
      ) {
        return <div key={event._id} />;
      }
      return (
        <Grid key={event._id} item style={{ marginBottom: 20 }}>
          <Card sx={{ width: 345 }}>
            <CardMedia
              sx={{ height: 140 }}
              image={event.imageUrl}
              title={event.name}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {event.name}
              </Typography>
              {renderCardContent(event)}
            </CardContent>
            <Grid container spacing={2} style={{ padding: 12 }}>
              {["active", "pending", "completed"].includes(
                event.status
              ) ? null : (
                <Grid item xs={6}>
                  <Button
                    size="small"
                    onClick={() => handleOpen(event.eventbriteUrl)}
                    disabled={event.checkedIn ? true : false}
                  >
                    Refund/Cancel
                  </Button>
                </Grid>
              )}

              {renderButtons(event)}
            </Grid>
          </Card>
        </Grid>
      );
    });
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      // justifyContent="center"
      // spacing={3}
      style={{ marginBottom: 50, paddingBottom: 200, width: "100%" }}
    >
      <Grid item style={{ marginBottom: 20 }}>
        <Chip
          label="Show All"
          variant={showAll ? "" : "outlined"}
          color="primary"
          onClick={() => setShowAll(!showAll)}
        />
      </Grid>
      {renderEvents()}
      <ConfirmRedirectDialog
        open={open}
        close={handleClose}
        confirm={handleOpenLink}
      />
      <InfoDialog open={infoOpen} close={handleCloseInfo} />
      {/* <CheckInDialog open={checkInOpen} close={handleCheckInClose} /> */}
      <MessageDialog
        open={dialogInfo.open}
        close={handleCheckInClose}
        title={dialogInfo.title}
        body={dialogInfo.body}
      />
    </Grid>
  );
};

export default MyEvents;
