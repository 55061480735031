import { useEffect, useState, forwardRef, useContext } from "react";

import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Avatar,
  CircularProgress,
  Box,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import HelpDialog from "./HelpDialog";
import NotesDialog from "./NotesDialog";

import { AppContext } from "../../contexts/AppContext";
import request from "../../utilities/requests";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AttendeePage = ({ attendee, open, close }) => {
  const { event } = useContext(AppContext);
  const [like, setLike] = useState(false);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [notesDialogOpen, setNotesDialogOpen] = useState(false);

  useEffect(() => {
    getPairing();
  }, []);

  const getPairing = async () => {
    const result = await request(
      `pairing/mixerpairing?eventId=${event._id}&otherUser=${attendee._id}`
    );
    if (result.success) {
      setLike(result.like);
    }
  };

  const handleClickLike = async () => {
    const body = {
      eventId: event._id,
      likedUser: attendee._id,
      like: !like,
    };
    const result = await request("pairing/mixerlike", "post", body);
    if (result.success) {
      setLike(result.like);
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <DialogContent
        sx={{ padding: 0, display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            flex: 2,
            overflow: "hidden",
            borderBottomLeftRadius: "10rem",
            borderBottomRightRadius: "10rem",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
            position: "relative",
          }}
        >
          {attendee?.profileImage ? (
            <img
              src={attendee.profileImage}
              alt="profileImg"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <AccountCircleIcon
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                color: "grey",
              }}
            />
          )}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            gap: 10
          }}
        >
          <Typography variant="h5">
            {attendee?.firstName} {attendee?.lastName}
          </Typography>
          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
              transform: "rotate(180deg)",
            }}
          >
            <CircularProgress
              variant="determinate"
              value={attendee?.matchScore}
              size={50}
              thickness={4}
              color="primary"
              aria-label={`Match score is ${attendee?.matchScore}%`}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{ fontWeight: "bold", transform: "rotate(180deg)" }}
              >
                {`${attendee?.matchScore}%`}
              </Typography>
            </Box>
          </Box>
          <Typography variant="caption">Match Score</Typography>
        </div>
        <div
          style={{
            flex: .6,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <HelpOutlineIcon
            style={{ color: "grey", cursor: "pointer", fontSize: 30 }}
            onClick={() => setHelpDialogOpen(true)}
          />
          <Button style={{ borderRadius: "50%" }}>
            <Avatar
              style={{
                height: 60,
                width: 60,
                backgroundColor: like === true ? "#68C371" : "grey",
              }}
              onClick={() => handleClickLike(true)}
            >
              <FavoriteIcon style={{ height: 30, width: 30 }} />
            </Avatar>
          </Button>
          <DescriptionIcon
            style={{ color: "grey", cursor: "pointer", fontSize: 30 }}
            onClick={() => setNotesDialogOpen(true)}
          />
        </div>
        <div
          style={{
            flex: .7,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="outlined" style={{ width: "80%" }} onClick={close}>
            Close
          </Button>
        </div>
      </DialogContent>
      <HelpDialog
        open={helpDialogOpen}
        close={() => setHelpDialogOpen(false)}
      />
      <NotesDialog
        open={notesDialogOpen}
        close={() => setNotesDialogOpen(false)}
        otherUser={attendee._id}
      />
    </Dialog>
  );
};

export default AttendeePage;
