import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

let useSnackbarRef;

export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

// Custom close button for the snackbars
const CloseButton = ({ key }) => {
  const { closeSnackbar } = useSnackbarRef;
  
  return (
    <IconButton size="small" onClick={() => closeSnackbar(key)}>
      <CloseIcon fontSize="small" style={{color: 'white'}}/>
    </IconButton>
  );
};

export const toast = {
  success(msg) {
    useSnackbarRef.enqueueSnackbar(msg, {
      variant: 'success',
      autoHideDuration: 3000, // Disappears after 3 seconds
      action: (key) => <CloseButton key={key} />, // Allows manual closing
    });
  },
  error(msg) {
    useSnackbarRef.enqueueSnackbar(msg, {
      variant: 'error',
      autoHideDuration: 3000,
      action: (key) => <CloseButton key={key} />,
    });
  },
  info(msg) {
    useSnackbarRef.enqueueSnackbar(msg, {
      variant: 'info',
      autoHideDuration: 3000,
      action: (key) => <CloseButton key={key} />,
    });
  },
  warning(msg) {
    useSnackbarRef.enqueueSnackbar(msg, {
      variant: 'warning',
      autoHideDuration: 3000,
      action: (key) => <CloseButton key={key} />,
    });
  },
};