// PreferredAgeRange.js

import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  FormHelperText,
} from "@mui/material";

const PreferredAgeRange = ({ question, onChange }) => {
  const {
    lowerRangeValue,
    upperRangeValue,
    lowerRangeLabel,
    upperRangeLabel,
  } = question;

  // Initialize state for lower and upper range
  const [lowerValue, setLowerValue] = useState("");
  const [upperValue, setUpperValue] = useState("");

  // Effect to initialize state based on existing answer
  useEffect(() => {
    if (question.answer) {
      const [initialLower, initialUpper] = question.answer
        .split(",")
        .map(Number);
      setLowerValue(initialLower);
      setUpperValue(initialUpper);
    }
  }, [question.answer]);

  // Generate the range of options for the Select components
  const rangeValues = Array.from(
    { length: upperRangeValue - lowerRangeValue + 1 },
    (_, i) => lowerRangeValue + i
  );

  // Generate the formatted dropdown options
  const formattedOptions = rangeValues.map((rangeValue) => {
    if (rangeValue === lowerRangeValue && lowerRangeLabel) {
      return `${rangeValue} - ${lowerRangeLabel}`;
    }
    if (rangeValue === upperRangeValue && upperRangeLabel) {
      return `${rangeValue} - ${upperRangeLabel}`;
    }
    return rangeValue.toString();
  });

  // Determine if there's an error:
  // 1. Missing required fields
  // 2. Lower age range is not less than upper age range
  const isMissingError =
    question.required &&
    (lowerValue === "" ||
      lowerValue === null ||
      upperValue === "" ||
      upperValue === null);

  const isRangeError =
    lowerValue !== "" &&
    upperValue !== "" &&
    lowerValue >= upperValue;

  const isError = isMissingError || isRangeError;

  // Handler for lower range change
  const handleLowerChange = (event) => {
    const newLower = event.target.value;
    setLowerValue(newLower);

    // If both values are selected and valid, update the parent
    if (newLower !== "" && upperValue !== "" && newLower < upperValue) {
      const formattedAnswer = `${newLower},${upperValue}`;
      onChange(question._id, formattedAnswer);
    } else {
      // If invalid, notify parent with null or appropriate value
      onChange(question._id, null);
    }
  };

  // Handler for upper range change
  const handleUpperChange = (event) => {
    const newUpper = event.target.value;
    setUpperValue(newUpper);

    // If both values are selected and valid, update the parent
    if (lowerValue !== "" && newUpper !== "" && lowerValue < newUpper) {
      const formattedAnswer = `${lowerValue},${newUpper}`;
      onChange(question._id, formattedAnswer);
    } else {
      // If invalid, notify parent with null or appropriate value
      onChange(question._id, null);
    }
  };

  return (
    <Grid item xs={12}>
      <Typography variant="subtitle1" gutterBottom>
        {question.question}
        {question.required && " *"}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        {/* Lower Range Select */}
        <Grid item xs={6}>
          <FormControl
            fullWidth
            error={isError && (isMissingError || isRangeError)}
          >
            <InputLabel id="lower-range-label">Youngest</InputLabel>
            <Select
              labelId="lower-range-label"
              id="lower-range-select"
              value={lowerValue}
              label="Lower Range"
              onChange={handleLowerChange}
            >
              {rangeValues.map((rangeValue) => (
                <MenuItem key={rangeValue} value={rangeValue}>
                  {formattedOptions[rangeValue - lowerRangeValue]}
                </MenuItem>
              ))}
            </Select>
            {/* Conditionally render FormHelperText for missing lower value */}
            {isMissingError && lowerValue === "" && (
              <FormHelperText>
                Please select the youngest age.
              </FormHelperText>
            )}
            {/* Conditionally render FormHelperText for range error */}
            {isRangeError && (
              <FormHelperText>
                Lower age range must be less than the upper age range.
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Upper Range Select */}
        <Grid item xs={6}>
          <FormControl
            fullWidth
            error={isError && (isMissingError || isRangeError)}
          >
            <InputLabel id="upper-range-label">Oldest</InputLabel>
            <Select
              labelId="upper-range-label"
              id="upper-range-select"
              value={upperValue}
              label="Upper Range"
              onChange={handleUpperChange}
            >
              {rangeValues.map((rangeValue) => (
                <MenuItem key={rangeValue} value={rangeValue}>
                  {formattedOptions[rangeValue - lowerRangeValue]}
                </MenuItem>
              ))}
            </Select>
            {/* Conditionally render FormHelperText for missing upper value */}
            {isMissingError && upperValue === "" && (
              <FormHelperText>
                Please select the oldest age.
              </FormHelperText>
            )}
            {/* Conditionally render FormHelperText for range error */}
            {isRangeError && (
              <FormHelperText>
                Upper age range must be greater than the lower age range.
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>

      {/* Description */}
      {question.description && (
        <Typography variant="caption" color="textSecondary" mt={2}>
          {question.description}
        </Typography>
      )}
    </Grid>
  );
};

export default PreferredAgeRange;