import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  HashRouter,
  Redirect,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";

import AuthContext from "./contexts/AuthContext";
import { AppContext } from "./contexts/AppContext";

import Auth from "./pages/Auth";
import Layout from "./components/Layout";
import Profile from "./pages/Profile";
import Matchmaking from "./pages/Matchmaking/Matchmaking";
import Events from "./pages/Events";
import MyEvents from "./pages/Events/components/MyEvents";
import ActiveEvent from "./pages/ActiveEvent/ActiveEvent";
import EventResults from "./pages/Events/components/EventResults";
import ContactUs from "./pages/ContactUs";
import Privacy from "./pages/Privacy/Privacy";

const Router = () => {
  const { signedIn, appStyle, socket, setPromptData } =
    React.useContext(AuthContext);
  const [event, setActiveEvent] = React.useState(null);
  const [events, setEvents] = React.useState([]);
  const [myEvents, setMyEvents] = React.useState([]);
  const [mixerAttendees, setMixerAttendees] = React.useState([]);

  const theme = createTheme({
    palette: {
      primary: {
        main: appStyle?.primaryColor ? appStyle.primaryColor : "#ef5241",
      },
    },
    typography: {
      fontFamily: appStyle?.fontFamily ? appStyle.fontFamily.join(",") : "",
    },
  });

  React.useEffect(() => {
    console.log(`Socket update: ${socket}`);
  }, [socket]);

  React.useEffect(() => {
    if (event && socket && socket.connected) {
      socket.emit("joinEvent", event._id);
      console.log("Joined event room");

      const promptHandler = (data) => {
        console.log("prompt received", data);
        setPromptData(data);

        try {
          if (Notification.permission === "granted") {
            new Notification("New Prompt!", {
              body: data.message || "Check out your new prompt!",
              // icon:appStyle ? appStyle.logo : null
            });
          } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                new Notification("New Prompt!", {
                  body: data.message || "Check out your new prompt!",
                  // icon:appStyle ? appStyle.logo : null
                });
              }
            });
          }
        } catch (e) {
          console.error("Prompt notifications failed");
        }
      };

      socket.on("showPrompt", promptHandler);

      // Cleanup to remove duplicate listener on re-render/unmount
      return () => {
        socket.off("showPrompt", promptHandler);
      };
    }
  }, [event, socket, setPromptData]);

  if (signedIn) {
    return (
      <HashRouter>
        <AppContext.Provider
          value={{
            event,
            setActiveEvent,
            // socket,
            events,
            setEvents,
            myEvents,
            setMyEvents,
            mixerAttendees,
            setMixerAttendees,
          }}
        >
          <Layout>
            <Routes>
              <Route index path="/" element={<MyEvents />} />
              <Route path="/events" element={<Events />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/matchmaking" element={<Matchmaking />} />
              <Route path="/activeevent" element={<ActiveEvent />} />
              <Route path="/eventresults" element={<EventResults />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/privacy" element={<Privacy />} />
            </Routes>
          </Layout>
        </AppContext.Provider>
      </HashRouter>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Auth />
    </ThemeProvider>
  );
};

export default Router;
