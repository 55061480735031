import React from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import DescriptionIcon from "@mui/icons-material/Description";
import request from "../../utilities/requests";

import PairingProfile from "./components/PairingProfile";
import PairingInfo from "./components/PairingInfo";
import PairingButtons from "./components/PairingButtons";
import HelpDialog from "./components/HelpDialog";
import NotesDialog from "./components/NotesDialog";
import CreateDateDialog from "./components/CreateDateDialog";
import DoneDialog from "./components/DoneDialog";
import DateSelector from "../DateSelector";

import "./styles.css";

const PairingEvent = () => {
  const navigate = useNavigate();
  const { event } = React.useContext(AppContext);
  const [pairingEvent, setPairingEvent] = React.useState(null);
  const [pairing, setPairing] = React.useState(null);
  const [canMoveForward, setCanMoveForward] = React.useState(false);
  const [canMoveBackward, setCanMoveBackward] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [helpDialogOpen, setHelpDialogOpen] = React.useState(false);
  const [notesDialogOpen, setNotesDialogOpen] = React.useState(false);
  const [doneDialogOpen, setDoneDialogOpen] = React.useState(false);
  const [dateDialogOpen, setDateDialogOpen] = React.useState(false);
  const [dateSelectorOpen, setDateSelectorOpen] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState(null);
  const timeoutRef = React.useRef(null); // Use a ref instead of state

  // const [round, setRound] = React.useState(1);

  React.useEffect(() => {
    if (!pairingEvent) {
      getPairingEvent();
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        console.log("Timeout cleared on unmount");
      }
    };
  }, [pairingEvent]);


  React.useEffect(() => {
    if (pairing) {
      if (
        pairing.like === true ||
        pairing.like === false ||
        pairing.noPairing
      ) {
        setCanMoveForward(true);
      } else {
        setCanMoveForward(false);
      }
    }
  }, [pairing]);

  React.useEffect(() => {
    if (pairing) {
      if (pairing.round === 1) {
        setCanMoveBackward(false);
      } else {
        setCanMoveBackward(true);
      }
      if (pairing.round === pairingEvent.numberOfRounds) {
        setCanMoveForward(false);
      } else {
        setCanMoveForward(true);
      }
    }
  }, [pairing]);



  const getPairingEvent = async () => {
    const result = await request("pairingevent/activeevent");
    if (!result.success) {
      console.error(result.error);
      navigate("/");
    } else if (result.success && !result.pairingEvent) {
      console.log("No active events. Heading back to events page");
      navigate("/");
    }
    setPairingEvent(result.pairingEvent);
    getPairingRound(result.pairingEvent.currentRound, result.pairingEvent._id);
  };

  const getPairingRound = async (round, pairingEventId) => {
    if (!pairingEventId) {
      pairingEventId = pairingEvent._id;
    }
    const body = { pairingEventId, round };
    const result = await request("pairingevent/pairinground", "post", body);
    if (!result.success) {
      alert(result.error);
      return;
    }

    if (result.pairing.roundNotActive) {
      alert(
        "The next round is not yet active. Please be patient and wait for it to start."
      );
    } else {
      setPairing({ ...result.pairing });
      if (pairingEvent){
        setPairingEvent({...pairingEvent, numberOfRounds: result.numberOfRounds})

      }
    }

    //if this is a pending round, set an interval to check for made matches
    if (result?.pairing?.status === "pending") {
      timeoutRef.current = setTimeout(() => {
        console.log("will check again in 5 seconds");
        getPairingRound(round, pairingEventId);
      }, 5000);
    }
  };

  const handleRoundController = async (btn) => {
    try {
      clearTimeout(timeoutRef.current);
    } catch (e) {
      console.error(`Error clearing timeout: ${e.message}`);
    }

    console.log(btn);

    let newRound;
    if (btn === "next") {
      newRound = pairing.round + 1;
    } else if (btn === "back") {
      newRound = pairing.round - 1;
    }
    setLoading(true);
    getPairingRound(newRound, pairingEvent._id);
    setLoading(false);
  };

  const handleRefresh = (data) => {
    setPairing(data);
    //check if user just entered like for last round --> display dialog if true
    if (data.round === pairingEvent.numberOfRounds && data.like !== undefined) {
      setDoneDialogOpen(true);
    }
  };

  const handleCreateDateDialog = () => {
    const reminder = localStorage.getItem("createDateReminder");
    if (!reminder) {
      setDateDialogOpen(true);
    } else {
      setDateSelectorOpen(true);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        height: "100%",
        marginTop: -20,
        overflowY:'hidden'
      }}
    >
      <div
        style={{
          flex: '0 0 50%',
          justifyContent: "center",
          alignItems: 'center',
        }}
      >
        <PairingProfile
          pairing={pairing}
          feedback={(btn) => handleRoundController(btn)}
          canMoveBackward={canMoveBackward}
          canMoveForward={canMoveForward}
          loading={loading}
        />
      </div>
      <div 
        style={{
          flex: '0 0 15%',
        }}
      >
        <PairingInfo pairing={pairing} />
      </div>
      <div
        style={{
          width:'100%',
          flex: '0 0 20%',
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PairingButtons
          pairing={pairing}
          pairingEventType={pairingEvent?.pairingEventType}
          refresh={(data) => handleRefresh(data)}
          canMoveBackward={canMoveBackward}
          canMoveForward={canMoveForward}
          feedback={(btn) => handleRoundController(btn)}
        />
      </div>
      <div
        style={{
          display: "flex",
          flex: '0 0 5%',
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <HelpOutlineIcon
          style={{ color: "grey", cursor: "pointer", fontSize: 30 }}
          onClick={() => setHelpDialogOpen(true)}
        />
        {pairing?._id ? (
          <DescriptionIcon
            style={{ color: "grey", cursor: "pointer", fontSize: 30 }}
            onClick={() => setNotesDialogOpen(true)}
          />
        ) : (
          <></>
        )}

        <SwapHorizontalCircleIcon
          style={{ color: "grey", cursor: "pointer", fontSize: 30 }}
          onClick={handleCreateDateDialog}
        />
      </div>
      <HelpDialog
        open={helpDialogOpen}
        close={() => setHelpDialogOpen(false)}
      />

      <NotesDialog
        open={notesDialogOpen}
        close={() => setNotesDialogOpen(false)}
        pairing={pairing}
      />

      <CreateDateDialog
        open={dateDialogOpen}
        close={() => setDateDialogOpen(false)}
        next={() => setDateSelectorOpen(true)}
      />
      <DateSelector
        open={dateSelectorOpen}
        close={() => setDateSelectorOpen(false)}
        round={pairing?.round}
      />
      <DoneDialog
        open={doneDialogOpen}
        close={() => setDoneDialogOpen(false)}
      />
    </div>
  );
};

export default PairingEvent;
