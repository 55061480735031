import React from "react";
import ReactGA from "react-ga4";
import axios from "axios";
import { io } from "socket.io-client";
import { setSocket } from "./utilities/socketService";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./utilities/SnackBarUtils";
import { createTheme, ThemeProvider } from "@mui/material";
import "@aws-amplify/ui-react/styles.css";

// import io from "socket.io-client";
import AuthContext from "./contexts/AuthContext";
import Router from "./Router";
import Ad from "./components/Ads/Ad";
import Prompt from "./components/Prompts/Prompt";

import "./App.css";

ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS}`);

function App() {
  const [signedIn, setSignedIn] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [token, setToken] = React.useState(null);
  const [appStyle, setAppStyle] = React.useState({});
  const [appConfig, setAppConfig] = React.useState({});
  const [orgId, setOrgId] = React.useState(null);
  const [adData, setAdData] = React.useState(null);
  const [promptData, setPromptData] = React.useState(null);
  const [socket, setSocket] = React.useState(null);

  // const socket = React.useRef(null);

  const theme = createTheme({
    palette: {
      primary: {
        main: appStyle?.primaryColor ? appStyle.primaryColor : "#ef5241",
      },
    },
    typography: {
      fontFamily: appStyle?.fontFamily ? appStyle.fontFamily.join(",") : "",
    },
  });

  React.useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      // console.log("requesting notifications");
      Notification.requestPermission();
    }
  });

  React.useEffect(() => {
    const getStyles = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_TNFT_SERVER}/style?url=${window.location.host}`
        );
        if (result?.data?.success) {
          setAppStyle(result?.data?.style);
          if (result?.data?.style?.favicon) {
            let fav = document.getElementById("favicon");
            fav.setAttribute("href", result.data.style.favicon);
          }
          if (result?.data?.style?.title) {
            document.title = result.data.style.title;
          }
          if (result?.data?.config) {
            setAppConfig(result.data.config);
          }
          if (result?.data?.orgId) {
            setOrgId(result.data.orgId);
          }
        }
      } catch (e) {
        console.error(e.message);
        return;
      }
    };
    getStyles();
  }, []);

  React.useEffect(() => {
    const si = localStorage.getItem(
      `${process.env.REACT_APP_COGNITO_CLIENT_ID}.signedIn`
    );
    if (si === "true") {
      setSignedIn(true);
    }
  }, []);

  React.useEffect(() => {
    // Only connect to socket if signedIn is true
    if (signedIn) {
      connectSocket();
    }
  }, [signedIn]); // R

  const connectSocket = async () => {
    console.log("connecting to socket");
    const token = localStorage.getItem(
      `${process.env.REACT_APP_COGNITO_CLIENT_ID}.accessToken`
    );
    const newSocket = io(`${process.env.REACT_APP_TNFT_SERVER}`, {
      path: "/appsock/socket.io",
      auth: { token },
      reconnection: true, // Enable reconnection attempts
      reconnectionAttempts: 10, // Number of reconnection attempts before giving up
      reconnectionDelay: 2000, // Delay between reconnection attempts (2 seconds)
      timeout: 5000,
    });

    

    newSocket.on("connect", () => {
      console.log("Connected to the server");
      setSocket(newSocket);
    });

    newSocket.on("disconnect", () => {
      console.log("Disconnected from the server");
    });

    // Listen for the "couplePriceUpdated" event
    newSocket.on("showad", (data) => {
      console.log("showing ad");
      setAdData(data);
    });

    // Clean up on component unmount
    return () => {
      newSocket.disconnect();
    };
  };

  return (
    <AuthContext.Provider
      value={{
        signedIn,
        setSignedIn,
        email,
        setEmail,
        token,
        setToken,
        appStyle,
        appConfig,
        orgId,
        socket,
        promptData,
        setPromptData,
      }}
    >
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <SnackbarUtilsConfigurator />
          <Router />
          <Ad data={adData} close={() => setAdData(null)} />
          <Prompt
            data={promptData}
            close={() => {
              console.log("closing prompt");
              setPromptData(null);
            }}
          />
        </SnackbarProvider>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}

export default App;
