import { useContext, useEffect } from "react";
import {
    Typography,
    List,
    ListItem,
    ListItemText,
    Button
  } from "@mui/material";
import AuthContext from "../contexts/AuthContext";




const ConfirmationHelp = ({setPage}) => {
    const { appConfig } = useContext(AuthContext);


    useEffect(() => {
        console.log(JSON.stringify(appConfig))
    },[appConfig])

    
    return (
      <div style={{display: 'flex', flexDirection: 'column', gap: 10, padding: 20}}>
        <Typography variant="h4" className="text-2xl font-bold mb-4">Troubleshooting: Not Receiving Your Verification Code</Typography>
        <Typography className="mb-4">If you have signed up for an account but have not received your <strong>6-digit verification code</strong>, follow these steps to resolve the issue.</Typography>
        
        <Typography variant="h5" className="text-xl font-semibold mt-4">1. Check Your Email Inbox and Spam Folder</Typography>
        <List className="list-disc pl-6">
          <ListItem><ListItemText>Look for an email from <strong>no-reply@thenextfunthing.com</strong>.</ListItemText></ListItem>
          <ListItem><ListItemText>Check your <strong>Spam, Junk, Promotions,</strong> or <strong>Other</strong> folders.</ListItemText></ListItem>
          <ListItem><ListItemText>Search for "verification code" in your email search bar.</ListItemText></ListItem>
        </List>
        
        <Typography variant="h5" className="text-xl font-semibold mt-4">2. Wait a Few Minutes and Try Again</Typography>
        <Typography>Sometimes, email delivery may take a few minutes due to network delays. Wait <strong>5-10 minutes</strong> and check again.</Typography>
        
        <Typography variant="h5" className="text-xl font-semibold mt-4">3. Request a New Verification Code</Typography>
        <Typography>If you haven’t received the email, try requesting a new code:</Typography>
        <List className="list-disc pl-6">
          <ListItem><ListItemText>Go back to the <strong>Confirmation</strong> page: <a href={`${window.location.origin}/?confirm=true`}>here</a></ListItemText></ListItem>
          <ListItem><ListItemText>Enter your email.</ListItemText></ListItem>
          <ListItem><ListItemText>Click where it says <strong>here</strong> in the "Click here to send a new one" text.</ListItemText></ListItem>
        </List>
        
        <Typography variant="h5" className="text-xl font-semibold mt-4">4. Ensure Your Email is Entered Correctly</Typography>
        <Typography>Check that you entered the correct email address when signing up. If incorrect, sign up again with the right email.</Typography>
        
        <Typography variant="h5" className="text-xl font-semibold mt-4">5. Check If Your Email Provider is Blocking the Email</Typography>
        <Typography>Some corporate or school email systems block automated emails. Try using a personal email like Gmail or Outlook. Hotmail is also notorious for spamming lot of emails.</Typography>
        
        <Typography variant="h5" className="text-xl font-semibold mt-4">6. Try Using a Different Email Address</Typography>
        <Typography>If all else fails, sign up again using a different email provider.</Typography>
        
        <Typography variant="h5" className="text-xl font-semibold mt-4">Still Having Trouble?</Typography>
        <Typography>If you’ve tried everything and still haven’t received your code, reach out to <a href={appConfig?.contactUsEmail}>{appConfig?.contactUsEmail}</a> for further assistance.</Typography>
        <Button onClick={() => setPage('login')}>back to login</Button>
      </div>
    );
  };
  
  export default ConfirmationHelp;
  