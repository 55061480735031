import React from "react";
import { Grid, Typography, Button, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import InfoIcon from '@mui/icons-material/Info';
import InfoDialog from "./InfoDialog";
import UpgradeDialog from "./UpgradeDialog";
import ConfirmDialog from "./ConfirmDialog";

import request from "../../../utilities/requests";




const Tier = ({ tier }) => {
  const theme = useTheme();
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [upgradeOpen, setUpgradeOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedTier, setSelectedTier] = React.useState(null);

  const updateTier = async () => {
    const body = { matchmakingTier: 1 };
    const result = await request("matchmaking/tier", "post", body);
    // if (result.success) {
    //     refresh();
    // }
  };

  const getTierLabel = () => {
    switch (tier) {
      case 1:
        return "Free";
      case 2:
        return "Silver";
      case 3:
        return "Gold";
      default:
        return "Free";
    }
  };

  const handleConfirm = (val) => {

    setSelectedTier(val);
    setUpgradeOpen(false);
    setConfirmOpen(true);

  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-around"
      spacing={3}
    >
      <Grid item xs={4}>
          <Typography variant="subtitle2" align="center" color={theme.palette.primary.main} style={{fontWeight: 'bold'}}>Tier: {getTierLabel()}</Typography>
      </Grid>
      <Grid item xs={4} style={{display:'flex', justifyContent:'center'}}>
        <InfoIcon style={{color: theme.palette.primary.main}} onClick={() => setInfoOpen(true)}/>
      </Grid>

      <InfoDialog open={infoOpen} close={() => setInfoOpen(false)} />
      <UpgradeDialog open={upgradeOpen}  close={() => setUpgradeOpen(false)} confirm={(val) => handleConfirm(val)}/>
      <ConfirmDialog open={confirmOpen} close={() => setConfirmOpen(false)} tier={selectedTier}/>
    </Grid>
  );
};

export default Tier;
