import * as React from "react";
import ReactGA from 'react-ga4';
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";


const UpgradeDialog = ({ open, close, confirm }) => {
  const navigate = useNavigate();

  React.useEffect(() =>{
    ReactGA.event({
      category: "matchmaking",
      action: "mm_open_upgrade_dialog"
    });
  },[])

  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle>Upgrading Your Matchmaking Tier</DialogTitle>
      <DialogContent>
        <DialogContentText>
          By upgrading your matchmaking tier, you're sending a clear signal to
          our matchmakers that you're fully committed to finding that special
          someone.
        </DialogContentText>
        <br />
        <DialogContentText>
          Whether you're aiming for priority in our matchmaking pool or you’re
          ready for our matchmakers to vet your next date, we have tailored
          options just for you!
        </DialogContentText>
        <br />
        {/* <DialogContentText style={{ fontWeight: "bold" }}>
          Silver Tier - $99 / year
        </DialogContentText>
        <br />
        <DialogContentText>
          You will receive a 1 hour consultation with one of our matchmakers to
          capture additional details about your preferences. You will be
          considered for matches ahead of free tier members and are thus more
          likely to be matched by our matchmakers.
        </DialogContentText> */}
        {/* <div
          style={{
            with: "100%",
            padding: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            size="small"
            onClick={() => confirm("silver")}
          >
            Upgrade to Silver
          </Button>
        </div> */}

        <DialogContentText style={{ fontWeight: "bold" }}>
          Gold Tier - $500 (One time fee)
        </DialogContentText>
        <br />
        <DialogContentText>
          You will receive a 1 hour consultation with one of our matchmakers to
          capture additional details about your preferences. You will then be set up on 
          2 dates that our matchmakers will completely plan for you.
        </DialogContentText>
        <div
          style={{
            with: "100%",
            padding: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            size="small"
            onClick={() => confirm("gold")}
          >
            Upgrade to Gold
          </Button>
        </div>

        <br />
        <DialogContentText>
          Still have questions? Click{" "}
          <span>
            <Typography variant="button" style={{ color: "rgb(239, 82, 65)", cursor:'pointer' }} onClick={() => navigate('/contactus', {state: {subject: 'Matchmaking'}})}>
              here
            </Typography>
          </span>{" "}
          to contact us.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default UpgradeDialog;
