import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, CircularProgress, Button } from "@mui/material";
import AttendeeCard from "../RoundComponents/AttendeeCard";
import AttendeePage from "../RoundComponents/AttendeePage";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import request from "../../utilities/requests";

import { toast } from "../../utilities/SnackBarUtils";

const GroupEvent = () => {
  const navigate = useNavigate();
  const [pairingEvent, setPairingEvent] = useState(null);
  const [round, setRound] = useState(null);
  const [roundData, setRoundData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedAttendee, setSelectedAttendee] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [canMoveForward, setCanMoveForward] = useState(false);
  const [canMoveBack, setCanMoveBack] = useState(false);
  const [onBreak, setOnBreak] = useState(false);

  useEffect(() => {
    if (!pairingEvent) {
      setLoading(true);
      getPairingEvent();
    }
  }, []);

  useEffect(() => {
    if (round === 1) {
      setCanMoveBack(false);
    } else {
      setCanMoveBack(true);
    }

    if (round === pairingEvent?.numberOfRounds) {
      setCanMoveForward(false);
    } else {
      setCanMoveForward(true);
    }
  }, [round]);

  const getPairingEvent = async () => {
    const result = await request("pairingevent/activeevent");
    if (!result.success) {
      toast.error(result.error);
      navigate("/");
    } else if (result.success && !result.pairingEvent) {
      toast.warning("No active events. Heading back to events page");
      navigate("/");
    }
    setPairingEvent(result.pairingEvent);
    setRound(result.pairingEvent.currentRound);
    getRound(result.pairingEvent._id, result.pairingEvent.currentRound);
  };

  const getRound = async (pairingEventId, round) => {
    if (!roundData) setLoading(true);
    const result = await request(
      `pairingevent/groupround?pairingEventId=${pairingEventId}&round=${round}`
    );
    if (result.success) {
      if (result.break) {
        setOnBreak(true);
        setRound(result.round);
        setRoundData(null);
      } else {
        setOnBreak(false);
        setRoundData(result.data);
        setRound(result.data.round);
      }
    } else {
      toast.error(result.error);
    }
    setLoading(false);
  };

  const handleSelectAttendee = (attendee) => {
    setSelectedAttendee(attendee);
    setDialogOpen(true);
  };

  const handleClickMove = async (btn) => {
    let newRound;
    if (btn === "next") {
      newRound = round + 1;
    } else if (btn === "back") {
      newRound = round - 1;
    }
    setLoading(true);
    await getRound(pairingEvent._id, newRound);
    setLoading(false);
  };

  const handleClose = () => {
    getRound(pairingEvent._id, round);
    setDialogOpen(false);
    setSelectedAttendee(null);
  };

  if (selectedAttendee) {
    return (
      <AttendeePage
        attendee={selectedAttendee}
        open={dialogOpen}
        close={handleClose}
      />
    );
  }

  const renderAttendees = () => {
    if (roundData) {
      return (
        <Grid container spacing={3} style={{ padding: 10 }}>
          {roundData?.users.map((attendee) => {
            return (
              <AttendeeCard
                key={attendee?._id}
                attendee={attendee}
                handleSelect={handleSelectAttendee}
              />
            );
          })}
        </Grid>
      );
    }
  };

  if (loading) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress size={100} />
      </div>
    );
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        style={{ height: "100%" }}
        // alignItems="space-between"
      >
        {onBreak ? (
          <Grid item xs={12} style={{ height: "40%" }}>
            <Typography
              variant="h4"
              align="center"
              style={{ marginBottom: 20 }}
            >
              You are on a break
            </Typography>
            <Typography variant="h5" align="center">
              Round: {round}
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={12}
              style={{
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
                borderBottomLeftRadius: "50%",
                borderBottomRightRadius: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: -20,
              }}
            >
              <Typography variant="h4" align="center">
                Round {roundData?.round}
              </Typography>
              <Typography variant="h5" align="center">
                Table: {roundData?.table}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", flexDirection: "column", justifyContent:'center' }}
            >
              {renderAttendees()}
            </Grid>
          </>
        )}

        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          {" "}
          {canMoveBack ? (
            <Button
              style={{ borderRadius: "50%" }}
              onClick={() => handleClickMove("back")}
            >
              <ArrowForwardIosIcon
                style={{
                  height: 40,
                  width: 40,
                  color: "#4e4e4e",
                  transform: "rotate(180deg)",
                }}
              />
            </Button>
          ) : (
            <div></div>
          )}
          {canMoveForward ? (
            <Button
              style={{ borderRadius: "50%" }}
              onClick={() => handleClickMove("next")}
            >
              <ArrowForwardIosIcon
                style={{ height: 40, width: 40, color: "#4e4e4e" }}
              />
            </Button>
          ) : (
            <div></div>
          )}
        </Grid>
      </Grid>
      <div style={{ height: 100 }} />
    </>
  );
};

export default GroupEvent;
