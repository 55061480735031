import React from "react";
import {
  Grid,
  Typography,
  Button,
  Avatar,
  CircularProgress,
} from "@mui/material";

import FavoriteIcon from "@mui/icons-material/Favorite";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DateSelector from "../../DateSelector";
import request from "../../../utilities/requests";

const PairingProfile = ({ pairing, feedback, loading }) => {
  const [data, setData] = React.useState({ name: "", table: "", round: "" });
  const [imageUrl, setImageUrl] = React.useState(null);
  const [imageLoading, setImageLoading] = React.useState(false);
  const [dateSelectorOpen, setDateSelectorOpen] = React.useState(false);
  const prevPairingRef = React.useRef();

  React.useEffect(() => {
    const prevPairing = prevPairingRef.current;
    if (pairing) {
      setData({
        name: pairing.person?.profile?.firstName || "",
        table: pairing.table || "",
        round: pairing.round || "",
      });

      if (prevPairing && prevPairing._id !== pairing._id) {
        setImageUrl(null);
        if (pairing.person?.profile?.profileImage) {
          getProfilePic(pairing.person._id);
        }
      } else if (!prevPairing && pairing.person?.profile?.profileImage) {
        getProfilePic(pairing.person._id);
      }

      // if (pairing.person?.profile?.profileImage) {
      //   getProfilePic(pairing.person._id);
      // } else {
      //   setImageUrl(null);
      //   setImageLoading(false); // Stop loading if no image to load
      // }
    } else {
      // Handle case when pairing is null or undefined
      setData({ name: "", table: "", round: "" });
      setImageUrl(null);
      setImageLoading(false);
    }
    prevPairingRef.current = pairing;
  }, [pairing]);

  const getProfilePic = async (userId) => {
    setImageLoading(true); // Start loading
    try {
      const result = await request(`profile/photo?userId=${userId}`);
      if (result.success) {
        console.log("Fetched Image URL:", result.url); // Debugging line
        // Preload the image
        const img = new Image();
        img.src = result.url;
        img.onload = () => {
          console.log("Preloaded Image loaded");
          setImageUrl(result.url);
          setImageLoading(false);
        };
        img.onerror = () => {
          console.log("Preloaded Image failed to load");
          setImageUrl(null);
          setImageLoading(false);
        };
      } else {
        console.log("Failed to fetch image:", result);
        setImageUrl(null); // Handle failure by clearing the image
        setImageLoading(false); // Stop loading since image won't load
      }
    } catch (error) {
      console.error("Failed to fetch profile picture:", error);
      setImageUrl(null); // Handle error by clearing the image
      setImageLoading(false); // Stop loading since image won't load
    }
    // imageLoading is handled in onLoad/onError or above
  };

  const renderName = () => {
    if (!data.name && !data.round) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    } else if (!data.name && data.round) {
      return (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item>
            <Typography gutterBottom variant="h4" align="center" >
              You have a break this round.
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography align="center" style={{ padding: 10 }}>
              Has your host placed you in a wildcard round? Only select a date
              if they have.
            </Typography>
            <Button
              variant="outlined"
              style={{ marginTop: 10 }}
              onClick={() => setDateSelectorOpen(true)}
            >
              Select a Date
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      if (imageUrl) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar src={imageUrl} style={{ width: 120, height: 120 }} />
            <Typography gutterBottom variant="h5" align="center">
              {data.name}
            </Typography>
          </div>
        );
      }
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AccountCircleIcon
            style={{ width: 100, height: 100, color: "grey" }}
          />
          <Typography gutterBottom variant="h5" align="center">
            {data.name}
          </Typography>
        </div>
      );
    }
  };

  const renderResult = () => {
    if (pairing?.like === true) {
      return (
        <Grid item style={{ marginTop: 10 }}>
          <Avatar style={{ height: 40, width: 40, backgroundColor: "#68C371" }}>
            <FavoriteIcon style={{ height: 20, width: 20 }} />
          </Avatar>
        </Grid>
      );
    } else if (pairing?.like === false) {
      return (
        <Grid item style={{ marginTop: 10 }}>
          <Avatar style={{ height: 40, width: 40, backgroundColor: "#E36262" }}>
            <CloseIcon style={{ height: 20, width: 20 }} />
          </Avatar>
        </Grid>
      );
    } else {
      return <></>;
    }
  };

  const renderImage = () => (
    <img
      key={imageUrl} // Ensure React treats this as a new element
      src={imageUrl}
      onLoad={() => {
        console.log("Image loaded");
        setImageLoading(false);
      }} // Image has loaded
      onError={() => {
        console.log("Error loading image");
        setImageLoading(false); // Stop loading on error
        setImageUrl(null); // Optionally handle the error by clearing the image
      }}
      style={{
        width: "100%",
        // height: "100%",
        // objectFit: "cover",
        position: "absolute",
        top: 0,
        left: 0,
      }}
      alt={`${data.name}'s profile`}
    />
  );

  if (pairing?.noPairing) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 30,
        }}
      >
        <Typography variant="h4" align="center" style={{marginTop: 100}}>
          You have a break this round
        </Typography>
        <Typography align="center" style={{ padding: 10 }}>
          Has your host placed you in a wildcard round? Only select a date if
          they have.
        </Typography>
        <Button variant="outlined" onClick={() => setDateSelectorOpen(true)}>
          Select a Date
        </Button>
        <DateSelector
          open={dateSelectorOpen}
          close={() => setDateSelectorOpen(false)}
          round={data?.round}
          pairing={pairing}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        overflow: "hidden",
        borderBottomLeftRadius: "10rem",
        borderBottomRightRadius: "10rem",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
        position: "relative",
      }}
    >
      {imageLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(255, 255, 255, 0.5)", // Optional: Add a semi-transparent overlay for better visibility
          }}
        >
          <CircularProgress style={{ width: 75, height: 75 }} />
        </div>
      ) : imageUrl ? (
        renderImage()
      ) : (
        <AccountCircleIcon
          style={{
            color: "grey",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      )}
    </div>
  );
};

export default PairingProfile;
