import React from "react";
import { Stack, Avatar, Button, Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import request from "../../../utilities/requests";

const PairingButtons = ({
  pairing,
  pairingEventType,
  refresh,
  canMoveForward,
  canMoveBackward,
  feedback,
}) => {
  const [noPairing, setNoPairing] = React.useState(true);
  const [like, setLike] = React.useState(null);
  const [friendEvent, setFriendEvent] = React.useState(false);

  React.useEffect(() => {
    if (pairing) {
      if (!pairing.noPairing) {
        setNoPairing(false);
      } else if (pairing.noPairing) {
        setNoPairing(true);
      }

      if (pairing.like === true) {
        setLike(true);
      } else if (pairing.like === false) {
        setLike(false);
      } else {
        setLike(null);
      }
    }
  }, [pairing]);

  React.useEffect(() => {
    if (pairingEventType === "friend") {
      setFriendEvent(true);
    }
  }, [pairingEventType]);

  const handleClickLike = async (btn) => {
    const body = {
      pairingId: pairing._id,
      like: btn,
    };

    const result = await request("pairing/like", "post", body);
    if (!result?.success) {
      alert(result.error);
      window.location.reload();
      return;
    }
    refresh(result.pairing);
  };

  const handleClickMove = (btn) => {
    if (
      typeof pairing?.like === "undefined" &&
      btn === "next" &&
      !pairing?.noPairing &&
      pairing?.status !== "pending" &&
      !friendEvent
    ) {
      alert("Please mark your match before proceeding to the next round.");
      return;
    }

    feedback(btn);
  };

  if (pairing?.status === "pending") {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        {canMoveBackward ? (
          <Button
            style={{ borderRadius: "50%" }}
            onClick={() => handleClickMove("back")}
          >
            <ArrowForwardIosIcon
              style={{
                height: 40,
                width: 40,
                color: "#4e4e4e",
                transform: "rotate(180deg)",
              }}
            />
          </Button>
        ) : (
          <div style={{ height: 40, width: 40 }} />
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="h5"
            align="center"
            style={{ fontWeight: "bold" }}
          >
            Pending
          </Typography>
          <Typography align="center" style={{ fontWeight: "bold" }}>
            Waiting for your date to set up the pairing on their side
          </Typography>
        </div>
        {canMoveForward ? (
          <Button
            style={{ borderRadius: "50%" }}
            onClick={() => handleClickMove("next")}
          >
            <ArrowForwardIosIcon
              style={{ height: 40, width: 40, color: "#4e4e4e" }}
            />
          </Button>
        ) : (
          <div style={{ height: 40, width: 40 }} />
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {noPairing ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {canMoveBackward ? (
            <Button
              style={{ borderRadius: "50%" }}
              onClick={() => handleClickMove("back")}
            >
              <ArrowForwardIosIcon
                style={{
                  height: 40,
                  width: 40,
                  color: "#4e4e4e",
                  transform: "rotate(180deg)",
                }}
              />
            </Button>
          ) : (
            <div style={{ height: 40, width: 40 }} />
          )}
          {canMoveForward ? (
            <Button
              style={{ borderRadius: "50%" }}
              onClick={() => handleClickMove("next")}
            >
              <ArrowForwardIosIcon
                style={{ height: 40, width: 40, color: "#4e4e4e" }}
              />
            </Button>
          ) : (
            <div style={{ height: 40, width: 40 }} />
          )}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {canMoveBackward ? (
            <Button
              style={{ borderRadius: "50%" }}
              onClick={() => handleClickMove("back")}
            >
              <ArrowForwardIosIcon
                style={{
                  height: 40,
                  width: 40,
                  color: "#4e4e4e",
                  transform: "rotate(180deg)",
                }}
              />
            </Button>
          ) : (
            <div style={{ height: 40, width: 62 }} />
          )}

          {friendEvent ? (
            <div></div>
          ) : (
            <Button style={{ borderRadius: "50%" }}>
              <Avatar
                style={{
                  height: 60,
                  width: 60,
                  backgroundColor: like === true ? "#68C371" : "grey",
                }}
                onClick={() => handleClickLike(true)}
              >
                <FavoriteIcon style={{ height: 30, width: 30 }} />
              </Avatar>
            </Button>
          )}

          {friendEvent ? (
            <div></div>
          ) : (
            <Button style={{ borderRadius: "50%" }}>
              <Avatar
                style={{
                  height: 60,
                  width: 60,
                  backgroundColor: like === false ? "#E36262" : "grey",
                }}
                onClick={() => handleClickLike(false)}
              >
                <CloseIcon style={{ height: 30, width: 30 }} />
              </Avatar>
            </Button>
          )}

          {canMoveForward ? (
            <Button
              style={{ borderRadius: "50%" }}
              onClick={() => handleClickMove("next")}
            >
              <ArrowForwardIosIcon
                style={{ height: 40, width: 40, color: "#4e4e4e" }}
              />
            </Button>
          ) : (
            <div style={{ height: 40, width: 40 }} />
          )}
        </div>
      )}
    </div>
  );
};

export default PairingButtons;
