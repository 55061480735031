import React from "react";
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
  TextField,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "../../../../utilities/SnackBarUtils";
import YesNo from "./components/YesNo";
import Choice from "./components/Choice";
import Range from "./components/Range";
import PreferredAgeRange from "./components/PreferredAgeRange";

import request from "../../../../utilities/requests";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EventProfile = ({ open, close, eventProfile }) => {
  const [questions, setQuestions] = React.useState([]);

  React.useEffect(() => {
    const getQuestions = async () => {
      const result = await request(
        `profilequestions/bygroup/?questionGroupId=${eventProfile?._id}`
      );
      if (result.success) {
        setQuestions(result.profileQuestions);
      }
    };
    if (eventProfile) getQuestions();
  }, [eventProfile]);

  const handleChange = async (id, answer) => {
    console.log("changing")
    const body = { questionId: id, answer };
    const result = await request("profilequestions/answer", "post", body);
    if (result.success) {
      const qs = questions.map((question) => {
        if (question._id === id) {
          return { ...question, answer };
        }
        return question;
      });
      toast.success("Profile updated")
      setQuestions(qs);
    }
  };

  const renderQuestions = () => {
    return questions.map((question) => {
      if (question.variant === "preferredAge") {
        return (
          <PreferredAgeRange
            key={question._id}
            question={question}
            onChange={handleChange}
          />
        );
      } else if (question.questionType === "yesno") {
        return <YesNo key={question._id} question={question} onChange={handleChange} />;
      } else if (question.questionType === "choice" || question.questionType === "multipleChoice") {
        return <Choice key={question._id} question={question} onChange={handleChange} />;
      } else if (
        question.questionType === "related" &&
        question.choices.length > 0
      ) {
        return <Choice key={question._id} question={question} onChange={handleChange} />;
      } else if (question.questionType === 'related' && question.choices.length === 0) {
        return <YesNo key={question._id} question={question} onChange={handleChange} />;
      } else if (question.questionType === 'range') {
        return <Range key={question._id} question={question} onChange={handleChange}/>
      }
    });
  };

  const handleClose = () => {
    setQuestions([]);
    close();
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Edit {eventProfile ? eventProfile?.name : ""}
          </Typography>
          {/* <Button autoFocus color="inherit" onClick={close}>
            save
          </Button> */}
        </Toolbar>
      </AppBar>
      <DialogContent >
        <Grid container spacing={3} >
          {renderQuestions()}
        </Grid>
        <Button variant="outlined" style={{width: '100%', marginTop: 30, marginBottom: 100}} onClick={handleClose}>close</Button>
      </DialogContent>
    </Dialog>
  );
};

export default EventProfile;
