import { forwardRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Typography,
} from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const SimplePopup = ({ open, close, data }) => {
  

  return (
    <Dialog
      open={open}
      onClose={() => close()}
      TransitionComponent={Transition}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{data?.title ? data.title : "Prompt"}</DialogTitle>
      <DialogContent>
        <Typography>{data?.text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimplePopup;
