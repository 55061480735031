import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  FormHelperText, // Imported for error messages
} from "@mui/material";

const Range = ({ question, onChange }) => {
  // Local state to manage the selected value
  const [value, setValue] = useState(question.answer || "");

  // Generate the range of options for the Select component
  const rangeValues = Array.from(
    { length: question.upperRangeValue - question.lowerRangeValue + 1 },
    (_, i) => question.lowerRangeValue + i
  );

  // Generate the formatted dropdown options
  const formattedOptions = rangeValues.map((rangeValue) => {
    if (rangeValue === question.lowerRangeValue && question.lowerRangeLabel) {
      return `${rangeValue} - ${question.lowerRangeLabel}`;
    }
    if (rangeValue === question.upperRangeValue && question.upperRangeLabel) {
      return `${rangeValue} - ${question.upperRangeLabel}`;
    }
    return rangeValue.toString();
  });

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue); // Update local state
    onChange(question._id, newValue); // Notify parent component
  };

  return (
    <Grid item xs={12}>
      <FormControl fullWidth error={question.required && !value}>
        <InputLabel>
          {question.question}
          {question.required ? " *" : ""}
        </InputLabel>
        <Select
          value={value}
          onChange={handleChange}
          label={question.question}
        >
          {rangeValues.map((rangeValue, index) => (
            <MenuItem key={rangeValue} value={rangeValue}>
              {formattedOptions[index]}
            </MenuItem>
          ))}
        </Select>
        {/* Conditionally render FormHelperText for errors */}
        {question.required && !value && (
          <FormHelperText>This field is required.</FormHelperText>
        )}
        {question.description && (
          <Typography variant="caption" color="textSecondary" mt={1}>
            {question.description}
          </Typography>
        )}
      </FormControl>
    </Grid>
  );
};

export default Range;