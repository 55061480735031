import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FirstLoginDialog = ({ open, close }) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <DialogTitle id="alert-dialog-title">
        {" Welcome to your profile!"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          To check into an event, your profile must include your first and last
          name and, depending on the event, a few questions about yourself. Each
          event may have a differnt set of questions, which you can find in the{" "}
          <span style={{ fontWeight: "bold" }}>My Event Profiles</span> section.
        </DialogContentText>
        <br />
        <DialogContentText>
          If you do not see any profiles in the My Event Profiles section, it means that you are not
          assigned to any events (i.e. you don't have a ticket). If that doesn't seem right, then 
          tap on the <span style={{ fontWeight: "bold" }}>Settings</span> and send us a message
          using the <span style={{ fontWeight: "bold" }}>Contact</span> form. 
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FirstLoginDialog;
