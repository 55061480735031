import React from "react";
import ReactGA from 'react-ga4'
import request from "../../utilities/requests";
import Layout from "../../components/Layout";
import { Grid } from "@mui/material";

import ProfileHeader from "./components/ProfileHeader";
import ProfileBody from "./components/ProfileBody";
import FirstLoginDialog from "./components/FirstLoginDialog";




const Profile = () => {
  const [profile, setProfile] = React.useState({});
  const [fields, setFields ] = React.useState({})
  const [loaded, setLoaded] = React.useState(false);
  const [firstLoginOpen, setFirstLoginOpen] = React.useState(false);

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/profile", title: "Profile" });
  },[])

  React.useEffect(() => {
    if (!loaded) {
      getProfile();
      setLoaded(true);
    }
  });

  React.useEffect(() => {
    if (profile.firstLogin === true) {
      console.log('first login')
      setFirstLoginOpen(true);
    }
  },[profile])

  const getProfile = async () => {
    const result = await request("profile");
    if (result.success) {
      setProfile(result.profile);
      setFields(result.fields)
    }
  };

  const handleClose = async () => {

    setFirstLoginOpen(false)
    const body = {
      firstLogin: false
    }
    const result = await request("profile/update", "post", body);
    
  }

  return (
    <Layout>
      <Grid container direction="column" spacing={3} alignItems="center" >
        <ProfileHeader profile={profile} refresh={getProfile}/>
        <ProfileBody profile={profile} fields={fields} refresh={getProfile}/>
        <FirstLoginDialog open={firstLoginOpen} close={handleClose} />
      </Grid>
    </Layout>
  );
};

export default Profile;
