import * as React from "react";
import ReactGA from 'react-ga4';
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const InfoDialog = ({ open, close }) => {
  const navigate = useNavigate();

  React.useEffect(() =>{
    ReactGA.event({
      category: "matchmaking",
      action: "mm_open_info_dialog"
    });
  },[])

  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle>Matchmaking</DialogTitle>
      <DialogContent>
        <DialogContentText>
        We love bringing people together and creating meaningful connections. Adding yourself to our dating pool is completely free and easy. Every day, we meet new singles who are excited about finding someone special, and we work hard to help spark those connections. Whether you’re looking for a fun new experience or hoping to meet “the one,” we’re here to help make it happen.        </DialogContentText>
        <br />
        <DialogContentText>
          Have questions? Click{" "}
          <span>
            <Typography variant="button" style={{ color: "rgb(239, 82, 65)", cursor:'pointer' }} onClick={() => navigate('/contactus', {state: {subject: 'Matchmaking'}})}>
              here
            </Typography>
          </span>{" "}
          to contact us.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()} autoFocus>
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
