import { useState, useEffect } from 'react';
import SimplePopup from './components/SimplePopup';
import FullScreen from "./components/FullScreen"
import request from '../../utilities/requests';


const Prompt = ({data, close}) => {
    const [open, setOpen] = useState(false);
    const [prompt, setPrompt] = useState(null);

    useEffect(() => {
        console.log(`Open: ${open}`)
        if (data) {
            getPrompt()
           
        } else {
            setOpen(false)
        }
    },[data])

    const getPrompt = async () => {

        const result = await request(`prompts/?id=${data.id}`);
        if (result.success) {
            setPrompt(result.prompt);
            setOpen(true)
        }


    }
    
        return <SimplePopup open={open} data={prompt} close={() => close()}/>

        // return <FullScreen open={open} data={data} close={() => close()}/>
    



   
}

export default Prompt