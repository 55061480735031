import React from "react";
import ReactGA from "react-ga4";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import PairingEventv2 from "../../components/PairingEventv2";
import MixerEvent from "../../components/MixerEvent";
import GroupEvent from "../../components/GroupEvent/GroupEvent";
import { CircularProgress } from "@mui/material";

import request from "../../utilities/requests";

const ActiveEvent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { page } = location.state || null;
  const { event, setActiveEvent } = React.useContext(AppContext);

  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/activeevent",
      title: "ActiveEvent",
    });
  }, []);

  React.useEffect(() => {
    if (!event) {
      getActiveEvent();
    }
  }, [event]);

  const getActiveEvent = async () => {
    const result = await request("events/activeevent");
    if (!result.success) {
      console.error(result.error);
      navigate("/events");
    }
    setActiveEvent(result.event);
  };

  const renderEventType = () => {
    //TODO: account for other events here as they are configured
    if (!event) {
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ height: 100, width: 100 }} />
        </div>
      );
    } else if (["mixer","guestList"].includes(page)) {
      return <MixerEvent />;
    } else if (event.pairingEventType === "group") {
      return <GroupEvent />;
    } else {
      return <PairingEventv2 />;
    }
  };

  return <>{renderEventType()}</>;
};

export default ActiveEvent;
