// YesNo.js

import { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  FormHelperText, // Imported for error messages
} from "@mui/material";

const YesNo = ({ question, onChange }) => {
  // Local state to manage the selected value
  const [value, setValue] = useState(question.answer || "");

  // Determine if there's an error: required and no value selected
  const isError = question.required && (value === "" || value === null);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue); // Update local state
    onChange(question._id, newValue); // Notify parent component
  };

  return (
    <Grid item xs={12}>
      <FormControl fullWidth error={isError}>
        <InputLabel>
          {question.question}
          {question.required ? " *" : ""}
        </InputLabel>
        <Select
          value={value}
          onChange={handleChange}
          label={question.question}
        >
          <MenuItem value="yes">Yes</MenuItem>
          <MenuItem value="no">No</MenuItem>
        </Select>
        {/* Conditionally render FormHelperText for errors */}
        {isError && (
          <FormHelperText>This field is required.</FormHelperText>
        )}
        {question.description && (
          <Typography variant="caption" color="textSecondary" mt={1}>
            {question.description}
          </Typography>
        )}
      </FormControl>
    </Grid>
  );
};

export default YesNo;