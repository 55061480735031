import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Avatar,
  Skeleton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import request from "../../../utilities/requests";

const iconSize = 120;

const ProfileHeader = ({ profile, refresh }) => {
  const inputFile = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [firstName, setFirstName] = React.useState(profile?.firstName || "");
  const [lastName, setLastName] = React.useState(profile?.lastName || "");
  const [noName, setNoName] = React.useState(false);

  useEffect(() => {
    if (profile) {
      setFirstName(profile?.firstName);
      setLastName(profile?.lastName);
      if (!profile?.firstName || !profile?.lastName) {
        setNoName(true);
      } else {
        setNoName(false);
      }
    }
  }, [profile]);

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    const body = new FormData();
    body.append("photo", file);
    body.append("Content-Type", "image/jpg");
    const token = localStorage.getItem(
      `${process.env.REACT_APP_COGNITO_CLIENT_ID}.accessToken`
    );
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_TNFT_SERVER}/profile/photo`,
      method: "post",
      data: body,
      headers: { "Content-Type": "multipart/form-data", Accesstoken: token },
    })
      .then(() => {
        refresh();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSave = async () => {
    const token = localStorage.getItem(
      `${process.env.REACT_APP_COGNITO_CLIENT_ID}.accessToken`
    );
    try {
      await axios({
        url: `${process.env.REACT_APP_TNFT_SERVER}/profile`,
        method: "put",
        data: { firstName, lastName },
        headers: { Accesstoken: token },
      });
      refresh();
      setOpen(false);
    } catch (error) {
      console.error("Error updating name:", error);
    }
  };

  const handleSaveName = async () => {
    const body = {
      firstName,
      lastName,
    };
    const result = await request("profile/update", "post", body);
    if (result.success) {
      refresh();
      setOpen(false);
    }
  };

  return (
    <Grid item>
      <Grid
        container
        direction="column"
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          {loading ? (
            <Skeleton variant="circular" width={iconSize} height={iconSize} />
          ) : (
            <Avatar
              src={profile.imageUrl}
              style={{ width: iconSize, height: iconSize, color: "grey" }}
              onClick={() => inputFile.current.click()}
            />
          )}
        </Grid>
        <Grid
          item
          style={{ paddingTop: 0, cursor: "pointer" }}
          onClick={() => inputFile.current.click()}
        >
          <Typography variant="overline" align="center">
            upload / change
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", gap: 10, alignItems: "center" }}
        >
          <Typography variant="h5">
            {profile?.firstName ? profile.firstName : ""}{" "}
            {profile?.lastName ? profile.lastName : ""}
          </Typography>
          <EditIcon
            style={{ color: "grey", width: 20, cursor: "pointer" }}
            onClick={() => setOpen(true)}
          />
        </Grid>
        {noName ? (
          <Grid item>
            <Typography variant="caption" style={{ color: "red" }}>
              Please click on the pencil icon to enter your name
            </Typography>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={handleUpload}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Edit Name</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveName} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ProfileHeader;
