import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Slide,
  CircularProgress,
  Box,
  Typography,
  IconButton,
} from "@mui/material"; // Import MUI components
import CloseIcon from '@mui/icons-material/Close'; // Import MUI close icon

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreen = ({ open, close, data }) => {
  const [progress, setProgress] = useState(100);
  const [timeLeft, setTimeLeft] = useState(data?.displayTime * 1000 || 5000); // Time left in milliseconds (default to 5000ms)
  const [canClose, setCanClose] = useState(false); // Tracks whether the user can close the ad

  useEffect(() => {
    if (open) {
      const displayTime = data?.displayTime * 1000 || 5000; // Convert displayTime to milliseconds
      setProgress(100); // Reset progress when dialog opens
      setTimeLeft(displayTime); // Reset time left
      setCanClose(false); // Prevent closing before countdown finishes

      // Start countdown
      const interval = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 100; // Decrease time by 100ms per tick
          if (newTime <= 0) {
            clearInterval(interval); // Stop the countdown at 0
            setTimeLeft(0); // Ensure timeLeft doesn't go below 0
            setProgress(0); // Ensure progress bar is fully depleted
            setCanClose(true); // Allow the 'X' button to be shown
            if (data?.autoClose) {
              close();
            }
            return 0;
          }
          setProgress((newTime / displayTime) * 100); // Update progress percentage
          return newTime;
        });
      }, 100);

      // Cleanup intervals on unmount or when the dialog closes
      return () => {
        clearInterval(interval);
      };
    }
  }, [open, close, data]);

  return (
    <Dialog
      open={open}
      fullScreen
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogContent style={{ backgroundColor: data?.style?.backgroundColor || null }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: '100%',
            position: 'relative',
          }}
        >
          <img src={data?.imageUrl} alt="Ad" style={{ width: '100%' }} />

          {/* Show Circular Progress if countdown is not finished */}
          {!canClose && (
            <Box sx={{ position: 'fixed', top: 10, right: 10 }}>
              <CircularProgress
                variant="determinate"
                value={progress}
                size={40} // You can adjust the size here
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="caption" component="div" color="text.secondary">
                  {`${Math.ceil(timeLeft / 1000)}s`} {/* Show seconds left */}
                </Typography>
              </Box>
            </Box>
          )}

          {/* Show the 'x' button after the countdown completes */}
          {canClose && (
            <IconButton
              onClick={close}
              sx={{
                position: 'fixed',
                top: 10,
                right: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FullScreen;