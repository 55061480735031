import React from "react";

import Login from "./components/Login";
import Signup from "./components/Signup";
import Confirm from "./components/Confirm";
import Forgot from "./components/Forgot";
import ConfirmForgot from "./components/ConfirmForgot";
import Success from "./components/Success";
import ConfirmationHelp from "../ConfirmationHelp";

const Auth = () => {
  const [page, setPage] = React.useState("login");

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const confirm = urlParams.get("confirm");
    if (confirm === 'true') {
        setPage('confirm')
    }
  }, []);

  switch (page) {
    case "login":
      return <Login setPage={(p) => setPage(p)} />;
    case "signup":
      return <Signup setPage={(p) => setPage(p)} />;
    case "confirm":
      return <Confirm setPage={(p) => setPage(p)} />;
    case "forgot":
      return <Forgot setPage={(p) => setPage(p)} />;
    case "confirm-forgot":
      return <ConfirmForgot setPage={(p) => setPage(p)} />;
    case "success":
      return <Success setPage={(p) => setPage(p)} />;
    case "troubleshoot":
      return <ConfirmationHelp setPage={(p) => setPage(p)} />

    default:
      return <Login />;
  }
};

export default Auth;
