import React from 'react';

export const AppContext = React.createContext({
    event: null,
    setActiveEvent: (event) => {},
    // socket: null,
    events: [],
    setEvents: (events) => {},
    myEvents: [],
    setMyevents: (myEvents) => {},
    mixerAttendees: [],
    setMixerAttendees: (attendees) => {}
});